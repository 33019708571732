import FinanceApi from '@/services/FinanceApi';

const getAll = async (config) => {
    return FinanceApi.get('/payment/package/products', config);
}

const get = async (id) => {
    return FinanceApi.get('/payment/package/products/' + id);
}

const store = async (data) => {
    if (typeof data.id != 'undefined' && data.id>0) {
        return FinanceApi.put('/payment/package/products/' + data.id, data);
    }
    return FinanceApi.post('/payment/package/products', data);
}

const update = async (id, formData) => {
    return FinanceApi.put('/payment/package/products/' + id, formData);
}

const del = async (id) => {
    return FinanceApi.delete('/payment/package/products/' + id);
}

const importProducts = async (data) => {
    return FinanceApi.post('/payment/package/products/import', data);
}

export default {
    getAll,
    get,
    store,
    update,
    del,
    importProducts
}
