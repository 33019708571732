<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('payment_packages')"
                        :isNewButton="checkPermission('paymentpackage_store')"
                        @new-button-click="add"
                        :actions="actions"
                        @filter-div-status="datatable.filterStatus = $event">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('payment_packages')"
                              :isNewButton="checkPermission('paymentpackage_store')"
                              @new-button-click="add"
                              @filter-div-status="datatable.filterStatus = $event">
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus"
                              @filter="$refs.datatable.getRows()"
                              @filterClear="$refs.datatable.filterClear()"
                              :exportExcel="true"
                              @exportExcel="$refs.datatable.excel()">
                <b-row>
                    <b-col md="6" xs="12">
                        <b-form-group :label="$t('name')">
                            <b-form-input v-model="datatable.queryParams.filter.name" />
                        </b-form-group>
                    </b-col>
                    <b-col md="6" xs="12">
                        <b-form-group :label="$t('scholarship_rate')">
                            <b-form-input type="number" step="1" v-model="datatable.queryParams.filter.scholarship_rate" />
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable ref="datatable"
                       :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       :lineNumbers="false"
                       v-show="datatable.showTable"
                       :data-function="callService">
            </datatable>
            <CommonModal ref="modal" size="md">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t(id == 0 ? "add" : "edit") }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div class="row mb-5 align-items-center">
                        <div class="col-12">
                            <ValidationObserver ref="storeForm">
                                <div class="row">
                                    <div class="col-12">
                                        <ValidationProvider name="name" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('name')">
                                                <b-form-input v-model="form.name">
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <ValidationProvider name="price" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('price')">
                                                <b-form-input v-model="form.price" type="number">
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <ValidationProvider name="scholarship_rate" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('scholarship_rate')">
                                                <b-form-input v-model="form.scholarship_rate" type="number">
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </ValidationObserver>
                        </div>
                        <div class="col-12 mt-3 d-flex">
                            <b-button @click="store" type="button" variant="primary" class="btn-lg mr-2">
                                {{ $t("save") | toUpperCase }}
                            </b-button>
                        </div>
                    </div>
                </template>
            </CommonModal>

            <CommonModal id="importProductsModal" size="xl" :onHideOnlyX="true">
                <template v-slot:CommonModalTitle>
                    {{ $t('edit').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="importProductsForm">
                        <b-row>
                            <b-col rows="12">
                                <ValidationProvider name="file" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('excel_file')">
                                        <div class="d-flex custom-file-upload">
                                            <b-form-file
                                                v-model="importProductsForm.file"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                :placeholder="$t('select_file')"
                                                :drop-placeholder="$t('drop_file')"
                                                ref="productsFile"
                                                multiple></b-form-file>
                                            <b-button variant="outline-secondary"
                                                      @click="$refs.productsFile.$el.childNodes[0].click();">
                                                {{ $t('browse') }}
                                            </b-button>
                                        </div>
                                        <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col rows="12" md="12" lg="12">
                                <b-form-group :label="$t('excel_format')">
                                    <div class="alert alert-info">
                                        Paket ID, Ürün ID, Sezon, Tip, Ücret, Peşin Ücret, Para Birimi
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col rows="12" md="12" lg="12">
                                <div class="d-flex justify-content-center mb-5">
                                    <b-button variant="primary" @click="uploadProducts">
                                        {{ $t('import').toUpper() }}
                                    </b-button>
                                </div>
                            </b-col>
                        </b-row>
                    </ValidationObserver>
                </template>
            </CommonModal>

            <CommonModal id="importProductInstallmentsModal" size="xl" :onHideOnlyX="true">
                <template v-slot:CommonModalTitle>
                    {{ $t('edit').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="importProductInstallmentsForm">
                        <b-row>
                            <b-col rows="12">
                                <ValidationProvider name="file" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('excel_file')">
                                        <div class="d-flex custom-file-upload">
                                            <b-form-file
                                                v-model="importProductInstallmentsForm.file"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                :placeholder="$t('select_file')"
                                                :drop-placeholder="$t('drop_file')"
                                                ref="installmentsFile"
                                                multiple></b-form-file>
                                            <b-button variant="outline-secondary"
                                                      @click="$refs.installmentsFile.$el.childNodes[0].click();">
                                                {{ $t('browse') }}
                                            </b-button>
                                        </div>
                                        <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col rows="12" md="12" lg="12">
                                <b-form-group :label="$t('excel_format')">
                                    <div class="alert alert-info">
                                        Paket ID, Ürün ID, Sezon, Tip, Taksit No, Tutar, Vade Tarihi
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col rows="12" md="12" lg="12">
                                <div class="d-flex justify-content-center mb-5">
                                    <b-button variant="primary" @click="uploadProductInstallments">
                                        {{ $t('import').toUpper() }}
                                    </b-button>
                                </div>
                            </b-col>
                        </b-row>
                    </ValidationObserver>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import qs from "qs";
import PaymentPackageService from "@/services/PaymentPackageService";
import PaymentPackageProductService from "@/services/PaymentPackageProductService";
import PaymentPackageProductInstallmentService from "@/services/PaymentPackageProductInstallmentService";
import CommonModal from "@/components/elements/CommonModal";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import PaymentProductsSelectbox from "@/components/interactive-fields/PaymentProductsSelectbox.vue";
import PaymentPeriodsSelectbox from "@/components/interactive-fields/PaymentPeriodsSelectbox.vue";

export default {
    components: {
        PaymentPeriodsSelectbox, PaymentProductsSelectbox,
        AppLayout,
        Header,
        HeaderMobile,

        DatatableFilter,
        Datatable,
        CommonModal,
        ValidationProvider,
        ValidationObserver,
    },
    metaInfo() {
        return {
            title: this.$t("payment_packages"),
        };
    },
    data() {
        return {
            id: 0,
            form: {},
            importProductsForm:{},
            importProductInstallmentsForm:{},
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: "",
                        field: "buttons",
                        html: true,
                        sortable: false,
                        tdClass: "p-0 text-center w-40 align-middle",
                        buttons: [
                            {
                                text: this.$t("edit"),
                                class: "ri-edit-box-line",
                                permission: "paymentpackage_update",
                                callback: (row) => {
                                    this.id = row.id;
                                    this.loadData();
                                },
                            },
                            {
                                text: this.$t("delete"),
                                class: "ri-delete-bin-2-line",
                                permission: "paymentpackage_delete",
                                callback: (row) => {
                                    this.delete(row.id);
                                },
                            },
                        ],
                    },
                    {
                        label: this.$t("id"),
                        field: "id",
                        sortable: true,
                    },

                    {
                        label: this.$t("name"),
                        field: "name",
                        sortable: true,
                    },
                    {
                        label: this.$t("price"),
                        field: "price",
                        sortable: true,
                    },
                    {
                        label: this.$t("scholarship_rate"),
                        field: "scholarship_rate",
                        sortable: true,
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: "id",
                    page: 1,
                    limit: 20,
                },
            },
            actions: [
                {
                    text: this.$t('import_products'),
                    //permission: 'paymentpackageproduct_import',
                    callback: () => {
                        this.$bvModal.show('importProductsModal');
                    }
                },
                {
                    text: this.$t('import_installments'),
                    //permission: 'paymentpackageproductinstallment_import',
                    callback: () => {
                        this.$bvModal.show('importProductInstallmentsModal');
                    }
                }
            ]
        };
    },
    methods: {
        callService(config) {
            return PaymentPackageService.getAll(config)
        },
        loadData() {
            if (this.id > 0) {
                PaymentPackageService.get(this.id)
                             .then((response) => {
                                 this.$refs.storeForm.reset();
                                 this.form = response.data.data;
                                 this.$refs.modal.$refs.commonModal.show();
                             })
                             .catch((error) => {
                                 if (error.data.message) {
                                     this.$toast.error(this.$t("api." + error.data.message));
                                 }
                             });
            }
        },
        async store() {
            const isValid = await this.$refs.storeForm.validate();
            if (isValid) {
                if (this.id == 0) {
                    PaymentPackageService.store(this.form, this.value)
                                 .then((response) => {
                                     this.$toast.success(this.$t("api." + response.data.message));
                                     this.$refs.datatable.getRows();
                                 })
                                 .catch((error) => {
                                     this.showErrors(error)
                                 })
                }
                else {
                    PaymentPackageService.update(this.id, this.form)
                                 .then((response) => {
                                     this.$toast.success(this.$t("api." + response.data.message));
                                     this.$refs.datatable.getRows();
                                 })
                                 .catch((error) => {
                                     this.showErrors(error)
                                 })
                }
            }
        },
        delete(id) {
            this.deleteModal(() => {
                PaymentPackageService.del(id)
                             .then((response) => {
                                 this.$toast.success(this.$t("api." + response.data.message));
                                 this.$refs.datatable.getRows();
                             })
                             .catch((error) => {
                                 this.$toast.error(this.$t("api." + error.data.message));
                             });
            });
        },
        add() {
            this.id = 0;
            this.$refs.storeForm.reset();
            this.form = {};
            this.$refs.modal.$refs.commonModal.show();
        },
        async uploadProducts() {
            const isValid = await this.$refs.importProductsForm.validate();
            if (isValid) {
                let formData = new FormData();
                formData.append('file', this.importProductsForm.file[0]);
                PaymentPackageProductService.importProducts(formData)
                    .then(response => {
                        if(response.data.data.success_count){
                            this.$toast.success(response.data.data.success_count + 'kayıt kaydedildi');
                        }
                        if(typeof response.data.data.errors != 'undefined'){
                            let messages=[];
                            for(const item of response.data.data.errors && response.data.data.errors.length>0){
                                messages.push(item.line+': '+item.message);
                            }
                            this.$swal.fire({
                                icon: 'error',
                                html: messages.join('<br>'),
                            })
                        }
                    })
                    .catch(e => {
                        this.showErrors(e, this.importProductsForm);
                    })
            }
        },
        async uploadProductInstallments() {
            const isValid = await this.$refs.importProductInstallmentsForm.validate();
            if (isValid) {
                let formData = new FormData();
                formData.append('file', this.importProductInstallmentsForm.file[0]);
                PaymentPackageProductInstallmentService.importInstallments(formData)
                    .then(response => {
                        if(response.data.data.success_count){
                            this.$toast.success(response.data.data.success_count + 'kayıt kaydedildi');
                        }
                        if(typeof response.data.data.errors != 'undefined' && response.data.data.errors.length>0){
                            let messages=[];
                            for(const item of response.data.data.errors){
                                messages.push(item.line+': '+item.message);
                            }
                            this.$swal.fire({
                                icon: 'error',
                                html: messages.join('<br>'),
                            })
                        }
                    })
                    .catch(e => {
                        this.showErrors(e, this.importProductInstallmentsForm);
                    })
            }
        }
    },
};
</script>

